import React, { Component } from 'react';
import './App.css';
const StoryblokClient = require('storyblok-js-client');
// const spaceId = 59954;
let Storyblok = new StoryblokClient({
  accessToken: '9XgvDOqHqXR0jqdKGOQE7gtt',
  cache: {
    clear: 'auto',
    type: 'memory'
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      works: [],
      exhibitions: [],
      press: [],
      publications: [],
      commissions: []
    };

    if (window.location.pathname === '/preview') {
      let password = prompt('Enter the password to view preview mode.');
      if (password === 'paulpreview') {
        this.loadWorks('draft');
        this.loadExhibitions('draft');
        this.loadCommissions('draft');
      } else {
        window.location.href = '/';
      }
      
    } else {
      this.loadWorks();
      this.loadExhibitions();
      this.loadCommissions();
    }
    this.loadLinks();
    this.loadPublications();
  }

  loadWorks(status = 'published') {
    let filter = '';
    if (status === 'draft') {
      // filter = {
      //   "preview_draft": {
      //     "in": true
      //   }
      // }
    } else {
      filter = {
        "preview_draft": {
          "in": false
        }
      }
    }
    Storyblok
      .get('cdn/stories', {
        starts_with: "work",
        resolve_relations: 'meta_data,notes,notes.works,mediums,date,dimensions',
        sort_by: 'position:asc',
        // version: status,
        filter_query: filter,
        per_page: 100
      })
      .then((response) => {
        // console.log('works response',response);
        this.setState({works: response.data.stories});
        // console.log("works state: ",this.state );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadExhibitions(status = 'published') {
    let filter = '';
    if (status === 'draft') {
      // filter = {
      //   "preview_draft": {
      //     "in": true
      //   }
      // }
    } else {
      filter = {
        "preview_draft": {
          "in": false
        }
      }
    }
    Storyblok
      .get('cdn/stories', {
        starts_with: "exhibitions",
        resolve_relations: 'works',
        sort_by: 'position:asc',
        // version: status,
        filter_query: filter,
        per_page: 100
      })
      .then((response) => {
        // console.log('exhibitions response',response);
        this.setState({exhibitions: response.data.stories});
        // console.log("exhibitions state: ",this.state );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadCommissions(status = 'published') {
    let filter = '';
    if (status === 'draft') {
      // filter = {
      //   "preview_draft": {
      //     "in": true
      //   }
      // }
    } else {
      filter = {
        "preview_draft": {
          "in": false
        }
      }
    }
    Storyblok
      .get('cdn/stories', {
        starts_with: "commissions",
        resolve_relations: 'works',
        sort_by: 'position:asc',
        // version: status,
        filter_query: filter,
        per_page: 100
      })
      .then((response) => {
        // console.log('exhibitions response',response);
        this.setState({commissions: response.data.stories});
        // console.log("exhibitions state: ",this.state );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadLinks() {
    Storyblok
      .get('cdn/stories', {
        starts_with: "press",
        sort_by: 'position:asc',
        per_page: 100
      })
      .then((response) => {
        // console.log('links response',response);
        this.setState({links: response.data.stories});
        // console.log("press state: ",this.state );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadPublications() {
    Storyblok
      .get('cdn/stories', {
        starts_with: "publications",
        sort_by: 'position:asc',
        per_page: 100
      })
      .then((response) => {
        // console.log('links response',response);
        this.setState({publications: response.data.stories});
        // console.log("press state: ",this.state );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderNoteWorkLinks(uuids) {
    // console.log(uuids);
    Storyblok
      .get('cdn/stories', {
        starts_with: "works",
        by_uuids: uuids.join()
      })
      .then((response) => {
        if (response.data.stories == null) {
          return <div>Loading links...</div>;
        }
        // console.log('note response',response);
        return response.data.stories.map((work) => {
          // console.log('notes work id',work.id);
          return <><a href="#hey">{work.id}</a>, </>;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    
  }

  renderMediums(mediums) {
    if (mediums == null) {
      return <div>Loading mediums...</div>;
    }
    return mediums.map((medium) => {
      return (
        <React.Fragment key={medium.id}>{medium.name}, </React.Fragment>
      );
    });
  }

  renderWorks() {
    if (this.state.works == null) {
      return <div>Loading...</div>;
    }
    // console.log('this.state.works',this.state.works);
    return this.state.works.map((work) => {
      // console.log('work.content.notes:', work.content.notes);
      return (
        <div className="work" key={work.id}>
          <div className="work-name">{work.name ? work.name : ""}
            <div className="work-details">
              {work.content.date !== null ? work.content.date.name : ""}, {this.renderMediums(work.content.mediums)} {work.content.dimensions ? work.content.dimensions.name : ""}
            </div>
          </div>
          <ol className="work-notes">
            {work.content.notes.map((note) => {
              // console.log('note.content.works',note.content.works);
              // console.log(this.renderNoteWorkLinks(note.content.works));
              return (
                <li key={note.id}>{note.name} {note.content.works.length > 0 ? this.renderNoteWorkLinks(note.content.works) : ''}</li>
              )
            })}
          </ol>
          <figure className="work-photo" style={{backgroundImage: `url(${work.content.photo})`}}>
            
          </figure>
        </div>
      );
    });
  }

  scrollTop() {
    let el = document.querySelector('main');
    el.scrollTop = 0;
  }

  scrollLeft(idName = '') {
    let el = document.querySelector(idName);
    if (el.scrollLeft > 0) {
      el.scrollLeft -= el.offsetWidth;
    }
  }

  scrollRight(idName = '') {
    let el = document.querySelector(idName);
    if (el.scrollLeft < (el.childElementCount * el.offsetWidth)) {
      el.scrollLeft += el.offsetWidth;
    }
    
  }

  renderExhibitions() {
    if (this.state.exhibitions == null) {
      return <div>Loading...</div>;
    }
    return this.state.exhibitions.map((exhibition) => {
      return (
        <div key={exhibition.slug} className="exhibition">
          <div className="exhibition-name">{exhibition.name ? exhibition.name : ""}
          </div>
          <nav className="exhibition-nav">
              <ul>
                <li onClick={() => { this.scrollLeft("#exhibition-images-"+exhibition.slug) }}>&larr;</li>
                <li onClick={() => { this.scrollRight("#exhibition-images-"+exhibition.slug)}}>&rarr;</li>
              </ul>
            </nav>
          <div className="exhibition-images" id={"exhibition-images-"+exhibition.slug}>
          {exhibition.content.images.length > 0 ? exhibition.content.images.map((image) => {
            return (
              <figure key={image.filename} className="exhibition-photo" style={{backgroundImage: `url(${image.filename})`}}></figure>
            )
          }) : ''}
          </div>
        </div>
      );
    });
  }

  renderCommissions() {
    if (this.state.commissions == null) {
      return <div>Loading...</div>;
    }
    return this.state.commissions.map((commission) => {
      return (
        <div key={commission.slug} className="commission">
          <div className="commission-name">{commission.name ? commission.name : ""}
          </div>
          <nav className="commission-nav">
              <ul>
                <li onClick={() => { this.scrollLeft("#commission-images-"+commission.slug) }}>&larr;</li>
                <li onClick={() => { this.scrollRight("#commission-images-"+commission.slug)}}>&rarr;</li>
              </ul>
            </nav>
          <div className="commission-images" id={"commission-images-"+commission.slug}>
          {commission.content.images.length > 0 ? commission.content.images.map((image) => {
            return (
              <figure key={image.filename} className="commission-photo" style={{backgroundImage: `url(${image.filename})`}}></figure>
            )
          }) : ''}
          </div>
        </div>
      );
    });
  }

  renderLinks() {
    if (this.state.links == null) {
      return <li>Loading...</li>;
    }

    return this.state.links.map((link) => {
      return (
        <li className="link" key={link.id}><a className="link-name" target="_blank" rel="noopener noreferrer" href={link.content.file !== "" ? link.content.file : link.content.url.url }>{link.name ? link.name : ""}</a></li>
      );
    });
  }

  renderPublications() {
    if (this.state.publications == null) {
      return <li>Loading...</li>;
    }

    return this.state.publications.map((publication) => {
      return (
        <li className="publication" key={publication.id}><a className="publication-name" target="_blank" rel="noopener noreferrer" href={publication.content.file !== "" ? publication.content.file : publication.content.url.url }>{publication.name ? publication.name : ""}</a></li>
      );
    });
  }

  render() {
    return (
        <main>
          <div className="intro-area">
            <header>
                <h1 onClick={() => {this.scrollTop()}}>Paul Mpagi Sepuya</h1>
                <ul className="nav">
                  <li><a href="#works" id="works-link">Works</a></li>
                  <li><a href="#exhibitions" id="exhibitions-link">Exhibitions</a></li>
                  <li><a href="#links" id="press-link">Press</a></li>
                  <li><a href="#publications" id="publications-link">Publications</a></li>
                  <li><a href="#commissions" id="commissions-link">Commissions</a></li>
                </ul>
            </header>
            
            <ul className="gallerists">
              <li><a href="https://vielmetter.com/artists/paul-mpagi-sepuya">Susanne Vielmetter Los Angeles Projects<span className="location">Los Angeles</span></a></li>
              <li><a href="http://documentspace.com/artists/paul-mpagi-sepuya/">DOCUMENT gallery <span className="location">Chicago</span></a></li>
              <li><a href="https://peterkilchmann.com/artists/paul-mpagi-sepuya">Galerie Peter Kilchmann <span className="location">Zürich, Paris</span></a></li>
              <li><a href="https://www.bortolamigallery.com">Bortolami Gallery <span className="location">New York</span></a></li>
              <li><a href="#" onClick={window.showMailingPopUp}>Newsletter</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1BgUvPswP1M3MberQdWMawz8jeADAkgcejsrwQY8hgq4/edit?usp=sharing">CV</a></li>
            </ul>
            <figure className="main-photo"></figure>
          </div>
          <div className="works-area" id="works">
            {this.renderWorks()}
          </div>
          <div className="exhibitions-area" id="exhibitions">
            {this.renderExhibitions()}
          </div>
          <div className="links-area" id="links">
            <ul>{this.renderLinks()}</ul>
          </div>
          <div className="publications-area" id="publications">
            <ul>{this.renderPublications()}</ul>
          </div>
          <div className="commissions-area" id="commissions">
            <ul>{this.renderCommissions()}</ul>
          </div>
        </main>
      );
    }
}

export default App;
